/* Brandon Regular */
@font-face {
  font-family: Brandon;
  src: url('Brandon_reg.otf');
}

@font-face {
  font-family: Brandon;
  font-style: italic;
  src: url('Brandon_reg_it.otf');
}

/* Brandon Bold */
@font-face {
  font-family: Brandon;
  font-weight: 700;
  src: url('Brandon_bld.otf');
}

@font-face {
  font-family: Brandon;
  font-weight: 700;
  font-style: italic;
  src: url('Brandon_bld_it.otf');
}

/* Brandon Black */
@font-face {
  font-family: Brandon;
  font-weight: 900;
  src: url('Brandon_blk.otf');
}

@font-face {
  font-family: Brandon;
  font-weight: 900;
  font-style: italic;
  src: url('Brandon_blk_it.otf');
}

/* Brandon Medium */
@font-face {
  font-family: Brandon;
  font-weight: 500;
  src: url('Brandon_med.otf');
}

@font-face {
  font-family: Brandon;
  font-weight: 500;
  font-style: italic;
  src: url('Brandon_med_it.otf');
}

/* Brandon Light */
@font-face {
  font-family: Brandon;
  font-weight: 300;
  src: url('Brandon_light.otf');
}

@font-face {
  font-family: Brandon;
  font-weight: 300;
  font-style: italic;
  src: url('Brandon_light_it.otf');
}

/* Brandon Thin */
@font-face {
  font-family: Brandon;
  font-weight: 100;
  src: url('Brandon_thin.otf');
}

@font-face {
  font-family: Brandon;
  font-weight: 100;
  font-style: italic;
  src: url('Brandon_thin_it.otf');
}
