@import 'assets/fonts/fonts.css';

body {
  margin: 0;
  font-family: Brandon, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4a4a4a;
}
body:has(dialog[open]) {
  overflow: hidden;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  font-size: 16px;
  font-family: Brandon, Helvetica, Arial, sans-serif;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

input {
  font-family: Brandon, Helvetica, Arial, sans-serif;
  font-size: inherit;
}
