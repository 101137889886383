.highcharts-container {
  overflow: visible !important;
}
.highcharts-tooltip {
  z-index: 1000;
}

.tooltip-table {
  height: 410px;
  width: 465px;
  border-collapse: collapse;
}

.tooltip-tr {
  border-bottom: 1px solid #e1e1e1;
}

.tooltip-td0 {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-align: center;
  text-transform: uppercase;
}

.tooltip-td2 {
  font-size: 1.25rem;
  font-weight: 700;
}

.tooltip-td3 {
  color: #464646;
  font-size: 0.75rem;
  opacity: 1;
}
